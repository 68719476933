import { render, staticRenderFns } from "./shareCount.vue?vue&type=template&id=b9229152&scoped=true"
import script from "./shareCount.vue?vue&type=script&lang=js"
export * from "./shareCount.vue?vue&type=script&lang=js"
import style0 from "./shareCount.vue?vue&type=style&index=0&id=b9229152&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9229152",
  null
  
)

export default component.exports